<template>
  <LoadingPage v-if="loaded" />
  <div v-else>
    <h5 class="font-weight-light text-uppercase mt-4 mb-4">
      {{ $t("settingsPage.title") }}
    </h5>
    <div class="notifications-settings-block">
      <h6 class="notifications-settings-block-title">
        {{ $t("settingsPage.sendFromEmail")
        }}<span v-if="this.settings.notification_email"
          >: {{ this.settings.notification_email }}</span
        >
      </h6>
      <div v-if="this.settings.notification_email">
        <p>
          {{ $t("settingsPage.emailConfirmed") }}:
          <em v-if="this.settings.sender_signature[0].Confirmed === true"
            ><i style="color: #6dc846" class="mr-1 fas fa-check mr-2"> </i>
          </em>
          <em v-else-if="this.settings.sender_signature[0].Confirmed === false"
            ><i
              style="color: #de4a1c"
              class="mr-1 fas fa-exclamation-triangle mr-2"
            >
            </i>
            <a
              href=""
              data-toggle="modal"
              data-target="#resendVerificationEmail"
            >
              {{ $t("settingsPage.textResendVerificationEmail") }}
            </a>
          </em>
        </p>
        <p>
          {{ $t("settingsPage.domainVerified") }}:
          <em v-if="this.settings.sender_signature[0].DKIMVerified === true"
            ><i style="color: #6dc846" class="mr-1 fas fa-check mr-2"> </i>
          </em>
          <em
            v-else-if="this.settings.sender_signature[0].DKIMVerified === false"
            ><i
              style="color: #de4a1c"
              class="mr-1 fas fa-exclamation-triangle mr-2"
            >
            </i>
            <a href="#" data-toggle="modal" data-target="#verifyDomain">{{
              $t("settingsPage.howtoVerifyDomain")
            }}</a></em
          >
        </p>
      </div>
      <div class="notifications-settings-block-content">
        <div
          class="notifications-settings-block-content-left-part"
          v-html="$t('settingsPage.textSendFromEmail')"
        ></div>
        <div class="notifications-settings-block-content-right-part">
          <button
            class="button-primary"
            v-on:click="copyEmail()"
            data-toggle="modal"
            data-target="#editEmail"
          >
            {{ $t("edit") }}
          </button>
        </div>
      </div>
    </div>
    <div class="notifications-settings-block">
      <h6 class="notifications-settings-block-title">
        {{ $t("settingsPage.sendFromSms") }}
      </h6>
      <div class="notifications-settings-block-content">
        <div
          class="notifications-settings-block-content-left-part"
          v-html="$t('settingsPage.textSendFromSms')"
        ></div>
        <div class="notifications-settings-block-content-right-part">
          <button
            class="button-secondary"
            v-if="settings.send_sms"
            data-toggle="modal"
            data-target="#confirmSMS"
          >
            {{ $t("disable") }}
          </button>
          <button
            class="button-primary"
            v-else
            data-toggle="modal"
            data-target="#confirmSMS"
          >
            {{ $t("enable") }}
          </button>
        </div>
      </div>
    </div>
    <div class="notifications-settings-block">
      <h6 class="notifications-settings-block-title">
        {{ $t("settingsPage.sendFromWhatsapp") }}
      </h6>
      <div class="notifications-settings-block-content">
        <div
          class="notifications-settings-block-content-left-part"
          v-html="$t('settingsPage.textSendFromWhatsapp')"
        ></div>
        <div class="notifications-settings-block-content-right-part">
          <template v-if="whatsapp_loader">
            <button
              class="button-secondary"
              disabled
              v-if="settings.send_whatsapp"
            >
              <span class="spinner-border spinner-border-sm"></span>
              {{ $t("settingsPage.disabling") }}
            </button>
            <button class="button-primary" disabled v-else>
              <span class="spinner-border spinner-border-sm"></span>
              {{ $t("settingsPage.enabling") }}
            </button>
          </template>
          <template v-else>
            <button
              class="button-secondary"
              data-toggle="modal"
              data-target="#confirmWhatsApp"
              v-if="settings.send_whatsapp"
            >
              {{ $t("disable") }}
            </button>
            <button
              class="button-primary"
              data-toggle="modal"
              data-target="#confirmWhatsApp"
              v-else
            >
              {{ $t("enable") }}
            </button>
          </template>
        </div>
      </div>
    </div>

    <div class="notifications-settings-block">
      <h6 class="notifications-settings-block-title">
        {{ $t("settingsPage.sendFromWebhook") }}
      </h6>
      <div class="notifications-settings-block-content">
        <div class="notifications-settings-block-content-left-part">
          {{ $t("settingsPage.textSendFromWebhook") }}
          <br /><br />
          <template
            v-if="settings.seller_webhook.url && settings.seller_webhook.key"
          >
            <p class="notifications-settings-block-title">
              {{ $t("settingsPage.webhook") }}
            </p>
            <div class="row">
              <div class="col-3">
                <b>{{ $t("settingsPage.url") }}</b> :
                {{ settings.seller_webhook.url }}
              </div>
              <div class="col">
                <b>{{ $t("settingsPage.key") }}</b> :
                {{ settings.seller_webhook.key }}
              </div>
              <div class="col">
                <b>{{ $t("settingsPage.header") }}</b> :
                {{ settings.seller_webhook.header }}
              </div>
            </div>
          </template>
        </div>
        <div class="notifications-settings-block-content-right-part">
          <button
            class="button-primary"
            v-on:click="copyWebhook()"
            data-toggle="modal"
            data-target="#addWebhook"
          >
            {{ $t("create") }}
          </button>
        </div>
      </div>
    </div>

    <!-- Modal add webhook-->
    <div
      class="modal fade"
      id="addWebhook"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addWebhookLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addWebhookLabel">
              {{ $t("settingsPage.addWebhookUrl") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="webhook-url"
                    >{{ $t("settingsPage.url") }}*
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="webhook-url"
                    placeholder="domain@domain.com"
                    v-model="webhook.url"
                    :class="{ 'is-invalid': errors['webhook_url'] }"
                  />
                  <div
                    class="invalid-feedback"
                    v-for="(error, index) in errors['webhook_url']"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="webhook-key">{{ $t("settingsPage.key") }} </label>
                  <input
                    type="text"
                    class="form-control"
                    id="webhook-key"
                    placeholder="dnoeznonioefz49ef48ze4cf"
                    v-model="webhook.key"
                    :class="{ 'is-invalid': errors['webhook_key'] }"
                  />
                  <div
                    class="invalid-feedback"
                    v-for="(error, index) in errors['webhook_key']"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="webhook-header"
                    >{{ $t("settingsPage.header") }}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="webhook-header"
                    placeholder="Authorization"
                    v-model="webhook.header"
                    :class="{ 'is-invalid': errors['webhook_header'] }"
                  />
                  <div
                    class="invalid-feedback"
                    v-for="(error, index) in errors['webhook_header']"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              id="addWebhookClose"
              type="button"
              class="button-light"
              data-dismiss="modal"
            >
              {{ $t("cancel") }}
            </button>

            <template v-if="webhook_loader">
              <button type="button" class="button-primary" disabled>
                <span class="spinner-border spinner-border-sm"></span>
                {{ $t("creating") }}
              </button>
            </template>
            <template v-else>
              <button
                type="button"
                class="button-primary"
                v-on:click="updateWebHook()"
              >
                {{ $t("create") }}
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal edit email-->
    <div
      class="modal fade"
      id="editEmail"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editEmailLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editEmailLabel">
              {{ $t("settingsPage.editDomain") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="notification-email"
                    >{{ $t("settingsPage.notificationEmail") }}* ({{
                      $t("settingsPage.noPublicEmail")
                    }})</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="notification-email"
                    placeholder="Email .."
                    v-model="notification_email"
                    :class="{ 'is-invalid': errors['notification_email'] }"
                  />
                  <div
                    class="invalid-feedback"
                    v-for="(error, index) in errors['notification_email']"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              id="editEmailClose"
              type="button"
              class="button-light"
              data-dismiss="modal"
            >
              {{ $t("cancel") }}
            </button>
            <template v-if="email_loader">
              <button type="button" class="button-primary" disabled>
                <span class="spinner-border spinner-border-sm"></span>
                {{ $t("uploading") }}
              </button>
            </template>
            <template v-else>
              <button
                type="button"
                class="button-primary"
                v-on:click="updateEmail()"
              >
                {{ $t("update") }}
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal verify domain instruction-->
    <div
      class="modal fade"
      id="verifyDomain"
      tabindex="-1"
      role="dialog"
      aria-labelledby="verifyEmailLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <p>{{ $t("settingsPage.howtoVerifyDomain") }}</p>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="this.settings.notification_email">
              <p>
                {{ $t("settingsPage.textHowtoVerifyDomain1") }}
                <b>{{ this.settings.sender_signature[0].domain }}</b>
                {{ $t("settingsPage.textHowtoVerifyDomain2") }}
              </p>
              <b-card>
                <p><b>Hostname:</b></p>
                <p>{{ this.settings.sender_signature[0].Hostmane }}</p>
                <p><b> Type:</b></p>
                <p>{{ this.settings.sender_signature[0].Type }}</p>
                <p><b> Value: </b></p>
                <p>{{ this.settings.sender_signature[0].Value }}</p>
              </b-card>
              <p>{{ $t("settingsPage.textHowtoVerifyDomain3") }}</p>
            </div>
          </div>
          <div class="modal-footer">
            <button
              id="verifyEmailClose"
              type="button"
              class="button-light"
              data-dismiss="modal"
            >
              {{ $t("close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal resend verification email-->
    <div
      class="modal fade"
      id="resendVerificationEmail"
      tabindex="-1"
      role="dialog"
      aria-labelledby="verifyEmailLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <p>{{ $t("settingsPage.textResendVerificationEmail") }}</p>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="this.settings.notification_email">
              <p>
                {{ $t("settingsPage.textYouWillSendVerificationEmail") }}
                {{ this.settings.notification_email }}
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <button
              id="resendEmailClose"
              type="button"
              class="button-light"
              data-dismiss="modal"
            >
              {{ $t("close") }}
            </button>
            <template v-if="email_loader">
              <button type="button" class="button-primary" disabled>
                <span class="spinner-border spinner-border-sm"></span>
                {{ $t("uploading") }}
              </button>
            </template>
            <template v-else>
              <button
                type="button"
                class="button-primary"
                v-on:click="resendVerificationEmail()"
              >
                {{ $t("resend") }}
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal confirm sms-->
    <div
      class="modal fade"
      id="confirmSMS"
      tabindex="-1"
      role="dialog"
      aria-labelledby="confirmSMSLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="confirmSMSLabel">
              {{ $t("confirmation") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            class="modal-body"
            v-html="
              $t('settingsPage.textWarnCostsSms', {
                val: `${settings.send_sms ? 'disable it?' : 'enable it?'}`,
              })
            "
          ></div>
          <div class="modal-footer">
            <button
              id="confirmSMSClose"
              type="button"
              class="button-light"
              data-dismiss="modal"
            >
              {{ $t("cancel") }}
            </button>
            <template v-if="sms_loader">
              <button
                class="button-secondary"
                disabled
                v-if="settings.send_sms"
              >
                <span class="spinner-border spinner-border-sm"></span>
                {{ $t("settingsPage.disabling") }}
              </button>
              <button class="button-primary" disabled v-else>
                <span class="spinner-border spinner-border-sm"></span>
                {{ $t("settingsPage.enabling") }}
              </button>
            </template>
            <template v-else>
              <button
                class="button-secondary"
                v-on:click="updateSMS()"
                v-if="settings.send_sms"
              >
                {{ $t("disable") }}
              </button>
              <button class="button-primary" v-on:click="updateSMS()" v-else>
                {{ $t("enable") }}
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal confirm whatsApp-->
    <div
      class="modal fade"
      id="confirmWhatsApp"
      tabindex="-1"
      role="dialog"
      aria-labelledby="confirmWhatsAppLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="confirmWhatsAppLabel">
              {{ $t("confirmation") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            class="modal-body"
            v-html="
              $t('settingsPage.textWarnCostsWhats', {
                val: `${settings.send_whatsapp ? 'disable it?' : 'enable it?'}`,
              })
            "
          ></div>
          <div class="modal-footer">
            <button
              id="confirmWhatsAppClose"
              type="button"
              class="button-light"
              data-dismiss="modal"
            >
              {{ $t("cancel") }}
            </button>
            <template v-if="whatsapp_loader">
              <button
                class="button-secondary"
                disabled
                v-if="settings.send_whatsapp"
              >
                <span class="spinner-border spinner-border-sm"></span>
                {{ $t("settingsPage.disabling") }}
              </button>
              <button class="button-primary" disabled v-else>
                <span class="spinner-border spinner-border-sm"></span>
                {{ $t("settingsPage.enabling") }}
              </button>
            </template>
            <template v-else>
              <button
                class="button-secondary"
                v-on:click="updateWhatsApp()"
                v-if="settings.send_whatsapp"
              >
                {{ $t("disable") }}
              </button>
              <button
                class="button-primary"
                v-on:click="updateWhatsApp()"
                v-else
              >
                {{ $t("enable") }}
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import $ from "jquery";
import NotificationSettingsService from "../../services/NotificationSettingsService";
import LoadingPage from "@/components/loadings/LoadingPage.vue";
import NProgress from "nprogress";
const notificationSettingsService = new NotificationSettingsService();

export default {
  name: "Settings",
  components: { LoadingPage },
  data: () => {
    return {
      settings: null,
      sms_loader: false,
      whatsapp_loader: false,
      webhook_loader: false,
      email_loader: false,
      loaded: true,
      webhook: {
        url: null,
        key: null,
        header: null,
      },
      notification_email: null,
      errors: [],
    };
  },
  beforeMount() {
    notificationSettingsService.getSettings().then((response) => {
      this.settings = response.data.data;
      this.loaded = false;
    });
  },
  methods: {
    copyWebhook() {
      this.webhook.url = this.settings.seller_webhook.url;
      this.webhook.key = this.settings.seller_webhook.key;
      this.webhook.header = this.settings.seller_webhook.header;
    },

    copyEmail() {
      this.notification_email = this.settings.notification_email;
    },

    updateEmail() {
      this.email_loader = true;
      notificationSettingsService
        .updateEmail({ notification_email: this.notification_email })
        .then(() => {
          notificationSettingsService.getSettings().then((response) => {
            this.settings = response.data.data;
            this.email_loader = false;
            document.getElementById("editEmailClose").click();
            this.$helpers.toast("Successfully updated setting", "success");
          });
        })
        .catch((error) => {
          this.email_loader = false;
          document.getElementById("editEmailClose").click();
          NProgress.done();
          this.$helpers.toast(
            error.response.data.messages.notification_email[0] ||
              "Email format is invalid or not allowed",
            "danger"
          );
        });
    },
    resendVerificationEmail() {
      this.email_loader = true;
      notificationSettingsService
        .resendVerificationEmail()
        .then(() => {
          this.email_loader = false;
          document.getElementById("resendEmailClose").click();
          this.$helpers.toast(
            "Successfully re-sent email verification",
            "success"
          );
        })
        .catch((error) => {
          this.email_loader = false;
          document.getElementById("resendEmailClose").click();
          NProgress.done();
          this.$helpers.toast(
            error.response.data.messages || "Error, try later",
            "danger"
          );
        });
    },

    updateWebHook() {
      this.webhook_loader = true;
      notificationSettingsService
        .updateWebHook({
          webhook: {
            url: this.webhook.url,
            key: this.webhook.key,
            header: this.webhook.header,
          },
        })
        .then(() => {
          notificationSettingsService.getSettings().then((response) => {
            this.settings = response.data.data;
            this.webhook_loader = false;
            document.getElementById("addWebhookClose").click();
            //$('#addWebhook').modal('hide');
          });
        });
    },

    updateSMS() {
      this.sms_loader = true;
      notificationSettingsService
        .updateSMS({ send_sms: !this.settings.send_sms })
        .then(() => {
          notificationSettingsService.getSettings().then((response) => {
            this.settings = response.data.data;
            this.sms_loader = false;
            document.getElementById("confirmSMSClose").click();
            //$('#confirmSMS').modal('hide');
          });
        });
    },

    updateWhatsApp() {
      this.whatsapp_loader = true;
      notificationSettingsService
        .updateWhatsApp({ send_whatsapp: !this.settings.send_whatsapp })
        .then(() => {
          notificationSettingsService.getSettings().then((response) => {
            this.settings = response.data.data;
            this.whatsapp_loader = false;
            document.getElementById("confirmWhatsAppClose").click();
            //$('#confirmWhatsApp').modal('hide');
          });
        });
    },
  },
};
</script>

<style lang="scss">
.notifications-settings-block {
  width: 100%;
  margin: 20px 0;
  padding: 25px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 10px var(--shadow);
  box-shadow: 0 0 10px var(--shadow);
}

.notifications-settings-block-title {
  font-weight: 600;
}

.notifications-settings-block-title span {
  font-weight: bolder;
  color: var(--primary);
  font-size: 15px;
}

.notifications-settings-block-content {
  display: flex;
  margin-top: 25px;
}

.notifications-settings-block-content-left-part {
  width: 80%;
}

.notifications-settings-block-content-right-part {
  width: 20%;
  position: relative;
}

.notifications-settings-block-content-right-part button {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
